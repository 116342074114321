import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import './index.css'
import JSONData from '../data/contact.json'

const ContactPage = () => (
  <Layout>
    <Seo title="Lowell CIS" />
    <div className="main">
      <StaticImage
        src="../images/logo.png"
        alt="Community Improvement Service"
        className="logo"
      />
      <h1 className="title">{JSONData.title}</h1>
      <div className="buttons">
        {JSONData.links.map((data) => {
          return <a className="button" href={data.url}>{data.name}</a>
        })}
      </div>
    </div>
  </Layout>
)

export default ContactPage
